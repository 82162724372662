/*******************************
         Site Overrides
*******************************/

.ui.label {
	font-weight: 400;
}

.ui.label > .icon{
	margin: 0 0.25rem 0 0;
}

.ui.label > .icon:only-child{
	margin: 0;
}

.ui.mini.labels .label, .ui.mini.label {
	font-size: 0.6rem;
}