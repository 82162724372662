/*******************************
         Site Overrides
*******************************/



.ui.header {
    font-weight: 800;
}

h5.ui.header{
    font-weight: 500;	
}
.ui.header .sub.header {
	color: #616061;
	font-weight: 300;
}